import React, { useEffect, useState } from "react";
import Layout from "../../../components/layout/Layout";
import Loader from "../../../components/loader/Loader";
import { Container } from "reactstrap";
import { AccelerationService, AnswerService } from "../../../services";
import {
  Acceleration,
  Answer,
  SearchFilter,
  SearchParams,
  User,
} from "../../../entities";

import TabPanel from "../../../components/TabPanel/TabPanel";
import TabPanelItem from "../../../components/TabPanel/TabPanelItem/TabPanelItem";
import PainSurvey from "../../../components/exercises/pain-survey/PainSurvey";
import PainMap from "../../../components/exercises/pain-map/PainMap";
import HypothesisListing from "../../../components/exercises/hypothesis-listing/HypothesisListing";
import WireframesShow from "../../../components/exercises/wireframes-show/WireframesShow";
import HypothesisTesting from "../../../components/exercises/hypothesis-testing/HypothesisTesting";
import MvpCharacteristics from "../../../components/exercises/mvp-characteristics/MvpCharacteristics";

import "./Acceleration.scss";
import { useParams } from "react-router-dom";
import UserStorage from "../../../util/userStorage";
import Phase2Week1 from "../../../components/exercises/phase2-week1/Phase2Week1";
import Phase2Week2 from "../../../components/exercises/phase2-week2/Phase2Week2";
import Phase2Week3 from "../../../components/exercises/phase2-week3/Phase2Week3";
import Phase2Week4 from "../../../components/exercises/phase2-week4/Phase2Week4";
import Phase2Week5 from "../../../components/exercises/phase2-week5/Phase2Week5";
import Phase2Week6 from "../../../components/exercises/phase2-week6/Phase2Week6";
import Phase2Week7 from "../../../components/exercises/phase2-week7/Phase2Week7";
import Phase2Week8 from "../../../components/exercises/phase2-week8/Phase2Week8";
import Phase3Week1 from "../../../components/exercises/phase3-week1/Phase3Week1";
import Phase3Week2 from "../../../components/exercises/phase3-week2/Phase3Week2";
import Phase3Week3 from "../../../components/exercises/phase3-week3/Phase3Week3";
import Phase3Week4 from "../../../components/exercises/phase3-week4/Phase3Week4";
import Phase3Week5 from "../../../components/exercises/phase3-week5/Phase3Week5";
import Phase3Week6 from "../../../components/exercises/phase3-week6/Phase3Week6";
import { UserService } from "../../../services/UserService";
import { Select } from "@chakra-ui/react";

export default function AccelerationPage() {
  const params: any = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [startups, setStartups] = useState<User[]>([]);
  const [acceleration, setAcceleration] = useState<Acceleration>(
    new Acceleration()
  );
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [phaseIdx, setPhaseIdx] = useState(0);
  const [weekIdx, setWeekIdx] = useState(0);

  useEffect(() => {
    const id = params.id;
    if (id) getAcceleration(id);
    if (UserStorage.get()?.role?.code === "licensed") {
      loadStartups();
    } else {
      setSelectedUser(UserStorage.get());
    }

    // eslint-disable-next-line
  }, [params.id]);

  useEffect(() => {
    if (selectedUser) {
      loadAnswers(selectedUser.id);
    }
  }, [selectedUser]);

  const getAcceleration = async (id: string) => {
    setIsLoading(true);

    const acceleration = await AccelerationService.get(id);
    setAcceleration(acceleration);
    setIsLoading(false);
  };

  const loadStartups = () => {
    const roleFilter = new SearchFilter({ key: "roleCode", value: "startup" });
    const params = new SearchParams([roleFilter], 1, 9999);
    UserService.search(params).then((result) => {
      const startups: User[] = [];
      for (const row of result.data) {
        startups.push(new User(row));
      }
      setStartups(startups);
    });
  };

  const loadAnswers = async (userId: string) => {
    console.log("user-id", userId);
    setIsLoading(true);
    const filterAnswer = [
      new SearchFilter({ key: "acceleration", value: params.id }),
      new SearchFilter({ key: "startup", value: userId }),
    ];
    const paramsAnswer = new SearchParams(filterAnswer, 1, 9999);
    const answersResult = await AnswerService.search(paramsAnswer);
    const storedAnswers: Answer[] = answersResult.data;

    for (const phase of acceleration.phases) {
      for (const week of phase.weeks)
        for (const exercise of week.exercises) {
          if (
            storedAnswers.find((a) => a.exercise.id === exercise.id) ===
            undefined
          ) {
            const answer = new Answer({
              acceleration,
              phase,
              week,
              exercise,
              startup: UserStorage.get(),
              answer: {},
              isCompleted: false,
            });
            storedAnswers.push(answer);
          }
        }
    }

    setAnswers(storedAnswers);
    setIsLoading(false);
  };

  const storeAnswer = (answer: Answer) => {
    const newAnswers = [...answers];

    const idx = newAnswers.findIndex(
      (a) => a.exercise.id === answer.exercise.id
    );
    if (idx >= 0) newAnswers[idx] = answer;
    else newAnswers.push(answer);

    setAnswers(newAnswers);
  };

  const save = async () => {
    setIsLoading(true);
    for (const exercise of acceleration.phases[phaseIdx].weeks[weekIdx]
      .exercises) {
      const answer = answers.find((a) => a.exercise.id === exercise.id);
      if (answer) {
        if (!answer.id) {
          await AnswerService.add(answer);
        } else {
          await AnswerService.update(answer);
        }
      }
    }
    setIsLoading(false);
  };

  const goFoward = async () => {
    await save();

    if (weekIdx + 1 < acceleration.phases[phaseIdx].weeks.length) {
      setWeekIdx(weekIdx + 1);
    } else {
      if (phaseIdx + 1 < acceleration.phases.length) {
        setPhaseIdx(phaseIdx + 1);
        setWeekIdx(0);
      }
    }
  };

  const goBack = async () => {
    await save();

    if (weekIdx > 0) {
      setWeekIdx(weekIdx - 1);
    } else {
      if (phaseIdx > 0) {
        setPhaseIdx(phaseIdx - 1);
        setWeekIdx(0);
      }
    }
  };

  return (
    <Layout internal={true}>
      {isLoading && <Loader />}
      <Container className="dashboard-container">
        {UserStorage.get()?.role?.code === "licensed" && (
          <div>
            <strong>Selecione a Startup:</strong>
            <Select
              value={selectedUser?.id}
              onChange={(event) => {
                const id = event.target.value;
                const startup = startups.find((s) => s.id === id);
                if (startup) {
                  setSelectedUser(startup);
                }
              }}
            >
              <option value="">Selecione uma Startup</option>
              {startups.map((startup, key) => (
                <option value={startup.id} key={key}>
                  {startup ? startup?.getAttributeValue("name") : ""}
                </option>
              ))}
            </Select>
            <br />
            <br />
          </div>
        )}
        <div className="dashboard-page-menu">
          <TabPanel
            activeTab={phaseIdx}
            setActiveTab={(tab: number) => {
              setPhaseIdx(tab);
              setWeekIdx(0);
            }}
          >
            {acceleration.phases.map((phase) => (
              <TabPanelItem tabName={phase.name} key={phase.id}>
                <TabPanel activeTab={weekIdx} setActiveTab={setWeekIdx}>
                  {phase.weeks.map((week, weekIndex) => (
                    <TabPanelItem tabName={week.name} key={week.id}>
                      {week.exercises.map((exercise) => (
                        <div key={exercise.id}>
                          {exercise.type?.id === "6460d9f865875800066cbf0d" &&
                            answers.find(
                              (answer) => answer?.exercise?.id === exercise.id
                            ) && (
                              <PainSurvey
                                id={exercise.id}
                                phase={phase}
                                week={week}
                                save={storeAnswer}
                                answer={
                                  answers.find(
                                    (answer) =>
                                      answer?.exercise?.id === exercise.id
                                  ) || new Answer({})
                                }
                              />
                            )}
                          {exercise.type?.id === "6460da0c65875800066cbf0f" &&
                            answers.find(
                              (answer) => answer?.exercise?.id === exercise.id
                            ) && (
                              <PainMap
                                id={exercise.id}
                                phase={phase}
                                week={week}
                                save={storeAnswer}
                                answer={
                                  answers.find(
                                    (answer) =>
                                      answer?.exercise?.id === exercise.id
                                  ) || new Answer()
                                }
                              />
                            )}
                          {exercise.type?.id === "6460da4565875800066cbf11" &&
                            answers.find(
                              (answer) => answer?.exercise?.id === exercise.id
                            ) && (
                              <HypothesisListing
                                id={exercise.id}
                                phase={phase}
                                week={week}
                                save={storeAnswer}
                                answer={
                                  answers.find(
                                    (answer) =>
                                      answer?.exercise?.id === exercise.id
                                  ) || new Answer()
                                }
                              />
                            )}
                          {exercise.type?.id === "6460e4cd65875800066cbf17" &&
                            answers.find(
                              (answer) => answer?.exercise?.id === exercise.id
                            ) && (
                              <WireframesShow
                                id={exercise.id}
                                phase={phase}
                                week={week}
                                save={storeAnswer}
                                answer={
                                  answers.find(
                                    (answer) =>
                                      answer?.exercise?.id === exercise.id
                                  ) || new Answer()
                                }
                              />
                            )}
                          {exercise.type?.id === "6460da4e65875800066cbf13" &&
                            answers.find(
                              (answer) => answer?.exercise?.id === exercise.id
                            ) && (
                              <HypothesisTesting
                                id={exercise.id}
                                phase={phase}
                                week={week}
                                save={storeAnswer}
                                answer={
                                  answers.find(
                                    (answer) =>
                                      answer?.exercise?.id === exercise.id
                                  ) || new Answer()
                                }
                              />
                            )}
                          {exercise.type?.id === "6460da7e65875800066cbf15" &&
                            answers.find(
                              (answer) => answer?.exercise?.id === exercise.id
                            ) && (
                              <MvpCharacteristics
                                id={exercise.id}
                                phase={phase}
                                week={week}
                                save={storeAnswer}
                                answer={
                                  answers.find(
                                    (answer) =>
                                      answer?.exercise?.id === exercise.id
                                  ) || new Answer()
                                }
                              />
                            )}
                          {exercise.type?.id === "64d4cd7fb3873c143871fa42" &&
                            answers.find(
                              (answer) => answer?.exercise?.id === exercise.id
                            ) && (
                              <Phase2Week1
                                id={exercise.id}
                                phase={phase}
                                week={week}
                                save={storeAnswer}
                                answer={
                                  answers.find(
                                    (answer) =>
                                      answer?.exercise?.id === exercise.id
                                  ) || new Answer()
                                }
                              />
                            )}
                          {exercise.type?.id === "64d4d08fb3873c143871fa58" &&
                            answers.find(
                              (answer) => answer?.exercise?.id === exercise.id
                            ) && (
                              <Phase2Week2
                                id={exercise.id}
                                phase={phase}
                                week={week}
                                save={storeAnswer}
                                answer={
                                  answers.find(
                                    (answer) =>
                                      answer?.exercise?.id === exercise.id
                                  ) || new Answer()
                                }
                              />
                            )}
                          {exercise.type?.id === "64d4d0a8b3873c143871fa59" &&
                            answers.find(
                              (answer) => answer?.exercise?.id === exercise.id
                            ) && (
                              <Phase2Week3
                                id={exercise.id}
                                phase={phase}
                                week={week}
                                save={storeAnswer}
                                answer={
                                  answers.find(
                                    (answer) =>
                                      answer?.exercise?.id === exercise.id
                                  ) || new Answer()
                                }
                              />
                            )}
                          {exercise.type?.id === "64d4d0d5b3873c143871fa5a" &&
                            answers.find(
                              (answer) => answer?.exercise?.id === exercise.id
                            ) && (
                              <Phase2Week4
                                id={exercise.id}
                                phase={phase}
                                week={week}
                                save={storeAnswer}
                                answer={
                                  answers.find(
                                    (answer) =>
                                      answer?.exercise?.id === exercise.id
                                  ) || new Answer()
                                }
                              />
                            )}
                          {exercise.type?.id === "64d4d0f4b3873c143871fa5c" &&
                            answers.find(
                              (answer) => answer?.exercise?.id === exercise.id
                            ) && (
                              <Phase2Week5
                                id={exercise.id}
                                phase={phase}
                                week={week}
                                save={storeAnswer}
                                answer={
                                  answers.find(
                                    (answer) =>
                                      answer?.exercise?.id === exercise.id
                                  ) || new Answer()
                                }
                              />
                            )}
                          {exercise.type?.id === "64d4d11cb3873c143871fa5d" &&
                            answers.find(
                              (answer) => answer?.exercise?.id === exercise.id
                            ) && (
                              <Phase2Week6
                                id={exercise.id}
                                phase={phase}
                                week={week}
                                save={storeAnswer}
                                answer={
                                  answers.find(
                                    (answer) =>
                                      answer?.exercise?.id === exercise.id
                                  ) || new Answer()
                                }
                              />
                            )}
                          {exercise.type?.id === "64d4d12db3873c143871fa5e" &&
                            answers.find(
                              (answer) => answer?.exercise?.id === exercise.id
                            ) && (
                              <Phase2Week7
                                id={exercise.id}
                                phase={phase}
                                week={week}
                                save={storeAnswer}
                                answer={
                                  answers.find(
                                    (answer) =>
                                      answer?.exercise?.id === exercise.id
                                  ) || new Answer()
                                }
                              />
                            )}
                          {exercise.type?.id === "64d4d16cb3873c143871fa5f" &&
                            answers.find(
                              (answer) => answer?.exercise?.id === exercise.id
                            ) && (
                              <Phase2Week8
                                id={exercise.id}
                                phase={phase}
                                week={week}
                                save={storeAnswer}
                                answer={
                                  answers.find(
                                    (answer) =>
                                      answer?.exercise?.id === exercise.id
                                  ) || new Answer()
                                }
                              />
                            )}
                          {exercise.type?.id === "64d4d188b3873c143871fa60" &&
                            answers.find(
                              (answer) => answer?.exercise?.id === exercise.id
                            ) && (
                              <Phase3Week1
                                id={exercise.id}
                                phase={phase}
                                week={week}
                                save={storeAnswer}
                                answer={
                                  answers.find(
                                    (answer) =>
                                      answer?.exercise?.id === exercise.id
                                  ) || new Answer()
                                }
                              />
                            )}
                          {exercise.type?.id === "64d4d1aab3873c143871fa61" &&
                            answers.find(
                              (answer) => answer?.exercise?.id === exercise.id
                            ) && (
                              <Phase3Week2
                                id={exercise.id}
                                phase={phase}
                                week={week}
                                save={storeAnswer}
                                answer={
                                  answers.find(
                                    (answer) =>
                                      answer?.exercise?.id === exercise.id
                                  ) || new Answer()
                                }
                              />
                            )}
                          {exercise.type?.id === "64d4d1ceb3873c143871fa62" &&
                            answers.find(
                              (answer) => answer?.exercise?.id === exercise.id
                            ) && (
                              <Phase3Week3
                                id={exercise.id}
                                phase={phase}
                                week={week}
                                save={storeAnswer}
                                answer={
                                  answers.find(
                                    (answer) =>
                                      answer?.exercise?.id === exercise.id
                                  ) || new Answer()
                                }
                              />
                            )}
                          {exercise.type?.id === "64d4d20eb3873c143871fa63" &&
                            answers.find(
                              (answer) => answer?.exercise?.id === exercise.id
                            ) && (
                              <Phase3Week4
                                id={exercise.id}
                                phase={phase}
                                week={week}
                                save={storeAnswer}
                                answer={
                                  answers.find(
                                    (answer) =>
                                      answer?.exercise?.id === exercise.id
                                  ) || new Answer()
                                }
                              />
                            )}
                          {exercise.type?.id === "64d4d224b3873c143871fa64" &&
                            answers.find(
                              (answer) => answer?.exercise?.id === exercise.id
                            ) && (
                              <Phase3Week5
                                id={exercise.id}
                                phase={phase}
                                week={week}
                                save={storeAnswer}
                                answer={
                                  answers.find(
                                    (answer) =>
                                      answer?.exercise?.id === exercise.id
                                  ) || new Answer()
                                }
                              />
                            )}
                          {exercise.type?.id === "64d4d224b3873c143871fa65" &&
                            answers.find(
                              (answer) => answer?.exercise?.id === exercise.id
                            ) && (
                              <Phase3Week6
                                id={exercise.id}
                                phase={phase}
                                week={week}
                                save={storeAnswer}
                                answer={
                                  answers.find(
                                    (answer) =>
                                      answer?.exercise?.id === exercise.id
                                  ) || new Answer()
                                }
                              />
                            )}
                          {UserStorage.get()?.role?.code !== "licensed" && (
                            <div className="exercises-buttons-container">
                              <button onClick={goBack}>Voltar</button>
                              <button onClick={goFoward}>Avançar</button>
                            </div>
                          )}
                        </div>
                      ))}
                    </TabPanelItem>
                  ))}
                </TabPanel>
              </TabPanelItem>
            ))}
          </TabPanel>
        </div>
      </Container>
    </Layout>
  );
}
