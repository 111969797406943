import React, { ReactNode, useEffect, useState } from "react"
import InputMask from "react-input-mask"
import { useNavigate, useParams } from "react-router-dom"
import Layout from "../../../../components/layout/Layout"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroupText,
  Label,
  Row,
} from "reactstrap"
import {
  BusinessCategory,
  BusinessSpecialty,
  SearchParams,
  User,
} from "../../../../entities"
import UserStorage from "../../../../util/userStorage"
import { UserService } from "../../../../services/UserService"

import "../../Dashboard.scss"
import { BusinessCategoryService } from "../../../../services/startup/BusinessCategoryService"
import { FileUploadService } from "../../../../services/upload/FileUploadService"
import { BusinessSpecialtyService } from "../../../../services"

const states = [
  { value: "AC", label: "Acre" },
  { value: "AL", label: "Alagoas" },
  { value: "AP", label: "Amapá" },
  { value: "AM", label: "Amazonas" },
  { value: "BA", label: "Bahia" },
  { value: "CE", label: "Ceará" },
  { value: "DF", label: "Distrito Federal" },
  { value: "ES", label: "Espírito Santo" },
  { value: "GO", label: "Goías" },
  { value: "MA", label: "Maranhão" },
  { value: "MT", label: "Mato Grosso" },
  { value: "MS", label: "Mato Grosso do Sul" },
  { value: "MG", label: "Minas Gerais" },
  { value: "PA", label: "Pará" },
  { value: "PB", label: "Paraíba" },
  { value: "PR", label: "Paraná" },
  { value: "PE", label: "Pernambuco" },
  { value: "PI", label: "Piauí" },
  { value: "RJ", label: "Rio de Janeiro" },
  { value: "RN", label: "Rio Grande do Norte" },
  { value: "RS", label: "Rio Grande do Sul" },
  { value: "RO", label: "Rondônia" },
  { value: "RR", label: "Roraíma" },
  { value: "SC", label: "Santa Catarina" },
  { value: "SP", label: "São Paulo" },
  { value: "SE", label: "Sergipe" },
  { value: "TO", label: "Tocantins" },
]

export default function ContactFormPage() {
  const navigate = useNavigate()
  const params = useParams()

  const [user, setUser] = useState(new User())
  const [businessCategories, setBusinessCategories] = useState<
    BusinessCategory[]
  >([])
  const [businessSpecialties, setBusinessSpecialties] = useState<
    BusinessSpecialty[]
  >([])
  const [typeLabel, setTypeLabel] = useState("")
  const [startupCase, setStartupCase] = useState({
    startup: "",
    description: "",
  })
  const [accelerationProgram, setAccelerationProgram] = useState({
    name: "",
  })

  const [documentMask, setDocumentMask] = useState("999.999.999-99")

  useEffect(() => {
    loadBusinessCategories()
    loadBusinessSpecialties()

    let type = ""
    if (window.location.href.includes("/mentor") === true) {
      type = "mentor"
      setTypeLabel("mentor")
    } else if (window.location.href.includes("/investidor") === true) {
      type = "investor"
      setTypeLabel("investidor")
    }

    setUser(
      new User({
        ...user,
        role: { code: type },
      })
    )

    if (params?.id === "me") {
      const user = UserStorage.get()
      if (user?.role?.code !== type) return
      if (!user?.id) return
      load(user.id, type)
    } else if (params?.id !== "0") {
      if (!params.id) return
      load(params.id, type)
    }

    // eslint-disable-next-line
  }, [params])

  const load = async (id: string, type: string) => {
    const dbUser = await UserService.get(id)
    if (dbUser?.role) {
      dbUser.role.code = type
    }
    setUser(dbUser)
  }

  const loadBusinessCategories = async () => {
    const params = new SearchParams([], 1, 9999)
    const result = await BusinessCategoryService.search(params)

    setBusinessCategories(result.data)
  }

  const loadBusinessSpecialties = async () => {
    const params = new SearchParams([], 1, 9999)
    const result = await BusinessSpecialtyService.search(params)

    setBusinessSpecialties(result.data)
  }

  const changeFile = async (event: any, fieldName: string) => {
    const img = {
      preview: URL.createObjectURL(event.target.files[0]),
      data: event.target.files[0],
    }

    let formData = new FormData()
    formData.append("file", img.data)

    const response = await FileUploadService.upload(formData)
    if (response.success === true) {
      changeFieldValue(fieldName, response?.file?.id)
    }
  }

  const save = async (event: any) => {
    event.preventDefault()

    if (!user.id) {
      if (user.password?.length < 6) {
        alert("A senha deve ter pelo menos 6 caracteres.")
        return false
      }
    }

    await UserService.save(user)
    if (params?.id === "me") {
      alert("Perfil salvo com sucesso.")
    } else {
      navigate(`/${typeLabel}`)
    }

    return false
  }

  const changeFieldValue = (code: string, value: any) => {
    const userNew = new User(user)
    userNew.setAttributeValue(code, value)
    setUser(userNew)
  }

  const changeFieldValueFromArray = (code: string, key: string, value: any) => {
    const userNew = new User(user)
    if (value) {
      userNew.addUpdateAttributeValueFromArray(code, key, value)
    } else {
      userNew.deleteAttributeValueFromArray(code, key)
    }

    setUser(userNew)
  }

  const addStartupCase = () => {
    if (startupCase.startup.length < 2) {
      alert("Digite o nome da startup.")
      return false
    }

    if (startupCase.description.length < 10) {
      alert("Digite a descrição do case")
      return false
    }

    changeFieldValueFromArray("startupCases", startupCase.startup, startupCase)
    // alert("Case adicionado com sucesso.")

    setStartupCase({
      startup: "",
      description: "",
    })
  }

  const addAccelerationProgram = () => {
    if (accelerationProgram.name.length < 2) {
      alert("Digite o nome do programa.")
      return false
    }

    changeFieldValueFromArray(
      "accelerationPrograms",
      accelerationProgram.name,
      accelerationProgram
    )
    // alert("Programa adicionado com sucesso.")

    setAccelerationProgram({
      name: "",
    })
  }

  return (
    <Layout internal={true}>
      <section className="profile-forms">
        <Form onSubmit={save}>
          <Row>
            <Col md={12}>
              <div className="title">
                <h1>
                  Cadastro de{" "}
                  {typeLabel.charAt(0).toUpperCase() + typeLabel.slice(1)}
                </h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="sub-title">
                <h2>Informações do Contato</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="contactName"
                  name="contactName"
                  placeholder="Digite o nome do Contato"
                  type="text"
                  required
                  value={user.getAttributeValue("name")}
                  onChange={(event) =>
                    changeFieldValue("name", event.currentTarget.value)
                  }
                />
                <Label for="contactName">Nome completo</Label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  tag={InputMask}
                  mask="999.999.999-99"
                  maskChar="-"
                  id="cpfOrCnpj"
                  name="cpfOrCnpj"
                  placeholder="Digite o CPF/CNPJ"
                  type="text"
                  required
                  value={user.getAttributeValue("document")}
                  onChange={(event) => {
                    let val = event.target.value
                    // val = val.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5")
                    changeFieldValue("document", val)
                  }}
                  // onKeyUp={(event: any) => {
                  //   let val = event.target.value
                  //   console.log(val)
                  //   if (val.replace(/\D/g, "").length > 11) {
                  //     setDocumentMask("99.999.999/9999-99")
                  //     changeFieldValue("document", val)
                  //   } else {
                  //     setDocumentMask("999.999.999-99")
                  //   }
                  // }}
                />
                <Label for="cpfOrCnpj">Digite o CPF ou CNPJ</Label>
              </FormGroup>
            </Col>
          </Row>
          {!user.id && (
            <Row>
              <Col md={6}>
                <FormGroup floating>
                  <Input
                    id="password"
                    name="password"
                    placeholder="Digite a senha"
                    type="password"
                    value={user.password}
                    onChange={(event) =>
                      setUser(
                        new User({
                          ...user,
                          password: event.currentTarget.value,
                        })
                      )
                    }
                  />
                  <Label for="password">Senha</Label>
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row>
            <Col md={4}>
              <FormGroup floating>
                <Input
                  id="email"
                  name="email"
                  placeholder="Digite o seu email"
                  type="email"
                  required
                  value={user.email}
                  onChange={(event) =>
                    setUser(
                      new User({ ...user, email: event.currentTarget.value })
                    )
                  }
                />
                <Label for="email">Email</Label>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup floating>
                <Input
                  tag={InputMask}
                  mask="+(99) 99 99999-9999"
                  maskChar={null}
                  id="phone"
                  name="phone"
                  placeholder="Digite o seu telefone"
                  type="text"
                  value={user.getAttributeValue("phone")}
                  onChange={(event) =>
                    changeFieldValue("phone", event.currentTarget.value)
                  }
                />
                <Label for="phone">Telefone</Label>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup floating>
                <Input
                  tag={InputMask}
                  mask="+(99) 99 99999-9999"
                  maskChar={null}
                  id="cellphone"
                  name="cellphone"
                  placeholder="Digite o seu telefone celular"
                  type="text"
                  required
                  value={user.getAttributeValue("mobile")}
                  onChange={(event) =>
                    changeFieldValue("mobile", event.currentTarget.value)
                  }
                />
                <Label for="cellphone">Telefone celular</Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup floating>
                <Input
                  id="linkedin"
                  name="linkedin"
                  placeholder="Digite o seu linkedin"
                  type="text"
                  value={user.getAttributeValue("linkedin")}
                  onChange={(event) =>
                    changeFieldValue("linkedin", event.currentTarget.value)
                  }
                />
                <Label for="linkedin">Linkedin</Label>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup floating>
                <Input
                  tag={InputMask}
                  mask="99/99/9999"
                  maskChar={null}
                  id="born"
                  name="born"
                  placeholder="Nascimento"
                  type="text"
                  value={user.getAttributeValue("born")}
                  onChange={(event) =>
                    changeFieldValue("born", event.currentTarget.value)
                  }
                />
                <Label for="born">Data de Nascimento</Label>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup floating>
                <Input
                  id="state"
                  name="state"
                  type="select"
                  value={user.getAttributeValue("state")}
                  onChange={(event) =>
                    changeFieldValue("state", event.target.value)
                  }
                >
                  <option value={""}>Selecionar Estado</option>
                  {states.map((state) => (
                    <option value={state.value}>{state.label}</option>
                  ))}
                </Input>
                <Label for="state">Estado</Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="sub-title">
                <h2>Modelos de Negócio</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={1}>
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  id="b2b"
                  checked={user.getAttributeValueFromArray(
                    "businessModel",
                    "b2b"
                  )}
                  onChange={(event) =>
                    changeFieldValueFromArray(
                      "businessModel",
                      "b2b",
                      event.target.checked
                    )
                  }
                />
                <Label for="b2b">B2B</Label>
              </FormGroup>
            </Col>
            <Col md={1}>
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  id="b2c"
                  checked={user.getAttributeValueFromArray(
                    "businessModel",
                    "b2c"
                  )}
                  onChange={(event) =>
                    changeFieldValueFromArray(
                      "businessModel",
                      "b2c",
                      event.target.checked
                    )
                  }
                />
                <Label for="b2c">B2C</Label>
              </FormGroup>
            </Col>
            <Col md={1}>
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  id="b2e"
                  checked={user.getAttributeValueFromArray(
                    "businessModel",
                    "b2e"
                  )}
                  onChange={(event) =>
                    changeFieldValueFromArray(
                      "businessModel",
                      "b2e",
                      event.target.checked
                    )
                  }
                />
                <Label for="b2e">B2E</Label>
              </FormGroup>
            </Col>
            <Col md={1}>
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  id="b2g"
                  checked={user.getAttributeValueFromArray(
                    "businessModel",
                    "b2g"
                  )}
                  onChange={(event) =>
                    changeFieldValueFromArray(
                      "businessModel",
                      "b2g",
                      event.target.checked
                    )
                  }
                />
                <Label for="b2g">B2G</Label>
              </FormGroup>
            </Col>
            <Col md={1}>
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  id="b2b2c"
                  checked={user.getAttributeValueFromArray(
                    "businessModel",
                    "b2b2c"
                  )}
                  onChange={(event) =>
                    changeFieldValueFromArray(
                      "businessModel",
                      "b2b2c",
                      event.target.checked
                    )
                  }
                />
                <Label for="b2b2c">B2B2C</Label>
              </FormGroup>
            </Col>
            <Col md={1}>
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  id="c2c"
                  checked={user.getAttributeValueFromArray(
                    "businessModel",
                    "c2c"
                  )}
                  onChange={(event) =>
                    changeFieldValueFromArray(
                      "businessModel",
                      "c2c",
                      event.target.checked
                    )
                  }
                />
                <Label for="c2c">C2C</Label>
              </FormGroup>
            </Col>
            <Col md={1}>
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  id="d2c"
                  checked={user.getAttributeValueFromArray(
                    "businessModel",
                    "d2c"
                  )}
                  onChange={(event) =>
                    changeFieldValueFromArray(
                      "businessModel",
                      "d2c",
                      event.target.checked
                    )
                  }
                />
                <Label for="d2c">D2C</Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="sub-title">
                <h2>Histórico/Bibliografia</h2>
              </div>
            </Col>
            <Col md={12}>
              <FormGroup floating>
                <Input
                  id="history"
                  name="history"
                  placeholder="Histórico"
                  type="textarea"
                  value={user.getAttributeValue("history")}
                  onChange={(event) =>
                    changeFieldValue("history", event.currentTarget.value)
                  }
                />
                <Label for="history">Histórico/Biografia</Label>
              </FormGroup>
            </Col>
          </Row>
          {user?.role?.code === "mentor" && (
            <>
              <Row>
                <Col md={12}>
                  <div className="sub-title">
                    <h2>Informações de Mentorias</h2>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      id="picture"
                      name="picture"
                      placeholder="Foto"
                      type="file"
                      onChange={(event) => changeFile(event, "picture")}
                    />
                    <Label for="picture">Foto</Label>
                  </FormGroup>
                  {user.getAttributeValue("picture") && (
                    <img
                      src={`${
                        process.env.REACT_APP_API_URI
                      }/file/get/${user.getAttributeValue("picture")}`}
                      alt="Foto"
                    />
                  )}
                </Col>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      id="cv"
                      name="cv"
                      placeholder="CV"
                      type="file"
                      onChange={(event) => changeFile(event, "cv")}
                    />
                    <Label for="cv">CV</Label>
                  </FormGroup>
                  {user.getAttributeValue("cv") && (
                    <a
                      href={`${
                        process.env.REACT_APP_API_URI
                      }/file/get/${user.getAttributeValue("cv")}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Abrir CV
                    </a>
                  )}
                </Col>
                <Col md={4}>
                  <FormGroup floating>
                    <Input
                      id="fakeName"
                      name="fakename"
                      placeholder="Nome Social"
                      type="text"
                      value={user.getAttributeValue("fakeName")}
                      onChange={(event) =>
                        changeFieldValue("fakeName", event.currentTarget.value)
                      }
                    />
                    <Label for="fakeName">Nome Social</Label>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup floating>
                    <Input
                      tag={InputMask}
                      mask="9999"
                      maskChar={null}
                      id="mentorStart"
                      name="mentorStart"
                      placeholder="Qual ano iniciou em inovação de startups?"
                      type="text"
                      min={1950}
                      value={user.getAttributeValue("mentorStart")}
                      onChange={(event) =>
                        changeFieldValue(
                          "mentorStart",
                          event.currentTarget.value
                        )
                      }
                    />
                    <Label for="mentorStart">
                      Qual ano iniciou em inovação de startups?
                    </Label>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup floating>
                    <Input
                      id="agregatedValue"
                      name="agregatedValue"
                      placeholder="Valor agregado"
                      type="number"
                      value={user.getAttributeValue("agregatedValue")}
                      onChange={(event) =>
                        changeFieldValue(
                          "agregatedValue",
                          event.currentTarget.value
                        )
                      }
                    />
                    <Label for="agregatedValue">Valor agregado</Label>
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}
          {user?.role?.code === "investor" && (
            <Row>
              <Col md={12}>
                <div className="sub-title">
                  <h2>Informações de Investimentos</h2>
                </div>
              </Col>
              <Col md={4}>
                <FormGroup floating>
                  <Input
                    id="fakeName"
                    name="fakename"
                    placeholder="Nome Social"
                    type="text"
                    value={user.getAttributeValue("fakeName")}
                    onChange={(event) =>
                      changeFieldValue("fakeName", event.currentTarget.value)
                    }
                  />
                  <Label for="fakeName">Nome Social</Label>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup floating>
                  <Input
                    id="investedStartupQuantity"
                    name="investedStartupQuantity"
                    placeholder="Número de startups investidas"
                    type="number"
                    value={user.getAttributeValue("investedStartupQuantity")}
                    onChange={(event) =>
                      changeFieldValue(
                        "investedStartupQuantity",
                        event.currentTarget.value
                      )
                    }
                  />
                  <Label for="investedStartupQuantity">
                    Número de startups investidas
                  </Label>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup floating>
                  <Input
                    id="investedStartup"
                    name="investedStartup"
                    placeholder="Startups investidas"
                    type="text"
                    value={user.getAttributeValue("investedStartup")}
                    onChange={(event) =>
                      changeFieldValue(
                        "investedStartup",
                        event.currentTarget.value
                      )
                    }
                  />
                  <Label for="investedStartup">Startups investidas</Label>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="averageTicketInvested">
                    Ticket Médio Aportado
                  </Label>
                  <div className="monetary-input">
                    <InputGroupText>R$</InputGroupText>
                    <Input
                      id="averageTicketInvested"
                      name="averageTicketInvested"
                      placeholder="Ticket Médio Aportado"
                      type="number"
                      value={user.getAttributeValue("averageTicketInvested")}
                      onChange={(event) =>
                        changeFieldValue(
                          "averageTicketInvested",
                          event.currentTarget.value
                        )
                      }
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="totalInvested">Total Aportado</Label>
                  <div className="monetary-input">
                    <InputGroupText>R$</InputGroupText>
                    <Input
                      id="totalInvested"
                      name="totalInvested"
                      placeholder="Total Aportado"
                      type="number"
                      value={user.getAttributeValue("totalInvested")}
                      onChange={(event) =>
                        changeFieldValue(
                          "totalInvested",
                          event.currentTarget.value
                        )
                      }
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row>
            <Col md={12}>
              <div className="sub-title">
                <h2>Verticais</h2>
              </div>
            </Col>
          </Row>
          <Row>
            {businessCategories.map((businessCategory) => (
              <Col md={2} key={businessCategory.id}>
                <div className="all-form-checkbox">
                  <FormGroup check inline>
                    {/* <Input id={businessCategory.id} type="checkbox" /> */}
                    <Label for={`interestLevel-${businessCategory.id}`} check>
                      {businessCategory.name}
                    </Label>
                  </FormGroup>
                </div>
                <div>
                  <FormGroup>
                    <Input
                      id={`interestLevel-${businessCategory.id}`}
                      name={`interestLevel-${businessCategory.id}`}
                      type="select"
                      value={user.getAttributeValueFromArray(
                        "businessCategories",
                        businessCategory.id
                      )}
                      onChange={(event) =>
                        changeFieldValueFromArray(
                          "businessCategories",
                          businessCategory.id,
                          event.target.value
                        )
                      }
                    >
                      <option value={""}>Nível de Interesse</option>
                      <option value={1}>Pouco</option>
                      <option value={2}>Médio</option>
                      <option value={3}>Muito</option>
                    </Input>
                  </FormGroup>
                </div>
              </Col>
            ))}
          </Row>
          <Row>
            <Col md={12}>
              <div className="sub-title">
                <h2>Especialidades</h2>
              </div>
            </Col>
          </Row>
          <Row>
            {businessSpecialties.map((specialty) => (
              <Col md={2} key={specialty.id}>
                <FormGroup switch>
                  <Input
                    type="switch"
                    role="switch"
                    checked={user.getAttributeValueFromArray(
                      "businessSpecialties",
                      specialty.id
                    )}
                    value={1}
                    onChange={(event) =>
                      changeFieldValueFromArray(
                        "businessSpecialties",
                        specialty.id,
                        event.target.checked
                      )
                    }
                  />
                  <Label check>{specialty.name}</Label>
                </FormGroup>
              </Col>
            ))}
          </Row>
          <Row>
            <Col md={12}>
              <div className="sub-title">
                <h2>Programas de Aceleração</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="programName"
                  name="programName"
                  placeholder="Digite o nome do programa"
                  type="text"
                  value={accelerationProgram.name}
                  onChange={(event) =>
                    setAccelerationProgram({
                      ...accelerationProgram,
                      name: event.target.value,
                    })
                  }
                />
                <Label for="programName">Nome</Label>
              </FormGroup>
            </Col>
            <Col md={2} className="profile-forms-add-button-container">
              <Button onClick={addAccelerationProgram}>Adicionar</Button>
            </Col>
          </Row>
          {Array.isArray(user.getAttributeValue("accelerationPrograms")) &&
            user.getAttributeValue("accelerationPrograms").map((item: any) => (
              <Row key={item.key}>
                <Col md={2}>{item.value.name}</Col>
                <Col md={2}>
                  <Button
                    className="form-small-button"
                    onClick={() =>
                      changeFieldValueFromArray(
                        "accelerationPrograms",
                        item.key,
                        false
                      )
                    }
                  >
                    Remover
                  </Button>
                </Col>
              </Row>
            ))}

          <Row>
            <Col md={12}>
              <div className="sub-title">
                <h2>Cases de Startup</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="startupName"
                  name="startupName"
                  placeholder="Digite o nome da Startup"
                  type="text"
                  value={startupCase.startup}
                  onChange={(event) =>
                    setStartupCase({
                      ...startupCase,
                      startup: event.target.value,
                    })
                  }
                />
                <Label for="startupName">Nome da Startup</Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="description"
                  name="description"
                  placeholder="Histórico"
                  type="textarea"
                  value={startupCase.description}
                  onChange={(event) =>
                    setStartupCase({
                      ...startupCase,
                      description: event.target.value,
                    })
                  }
                />
                <Label for="description">Descrição</Label>
              </FormGroup>
            </Col>
            <Col md={2} className="profile-forms-add-button-container">
              <Button onClick={addStartupCase}>Adicionar</Button>
            </Col>
          </Row>
          {Array.isArray(user.getAttributeValue("startupCases")) &&
            user.getAttributeValue("startupCases").map((item: any) => (
              <Row key={item.key}>
                <Col md={2}>{item.value.startup}</Col>
                <Col md={8}>{item.value.description}</Col>
                <Col md={2}>
                  <Button
                    className="form-small-button"
                    onClick={() =>
                      changeFieldValueFromArray("startupCases", item.key, false)
                    }
                  >
                    Remover
                  </Button>
                </Col>
              </Row>
            ))}
          <Button>Salvar</Button>
        </Form>
      </section>
    </Layout>
  )
}
