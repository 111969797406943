import React, { useState, useEffect } from 'react';
import {
  Row, Col, FormGroup, Input, Label, Button
} from 'reactstrap';
import { Answer } from '../../../entities';
import { ExerciseProps } from '../base';

import '../../exercises/Exercises.scss';
import './PainSurvey.scss';

interface SurveyAnswerSummary {
  label: string,
  number: number,
  goal: number,
  remaining: number
}


export default function PainSurvey(props: ExerciseProps) {
  const [researchLink, setResearchLink] = useState(props.answer.answer?.researchLink || '')
  const [surveyAnswers, setSurveyAnswers] = useState<SurveyAnswerSummary[]>([])

  useEffect(() => {
    const answers = [...surveyAnswers]
    if (!props.answer.answer.surveyAnswers) {
      answers.push({
        label: 'B2B',
        number: 0,
        goal: 100,
        remaining: 100
      })

      answers.push({
        label: 'B2C',
        number: 0,
        goal: 200,
        remaining: 200
      })
    } else {
      for (const surveyAnswer of props.answer.answer.surveyAnswers) {
        answers.push({
          label: surveyAnswer.label,
          number: surveyAnswer.number,
          goal: surveyAnswer.goal,
          remaining: surveyAnswer.remaining
        })
      }
    }

    setSurveyAnswers(answers)
    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    const isCompleted = researchLink.length >= 3
    const answer = new Answer({
      ...props.answer, 
      answer: {
        researchLink,
        surveyAnswers
      },
      isCompleted
    })
    
    props.save(answer)

    // eslint-disable-next-line
  }, [researchLink, surveyAnswers])

  return (
    <div className="page-content-container">
      <section className="exercises-container">
        <div className="title">
          <h1>{props.phase.name} - {props.week.name}</h1>
          <h2>Siga os passos a seguir e preencha os campos conforme solicitado para registrarmos a atividade.</h2>
        </div>
        <form className="pain-survey-form" action="">
          <Row className="pain-survey-row">
            <Col md={8}>
              <FormGroup floating>
                <Input
                  id="researchLink"
                  name="researchLink"
                  type="text"
                  placeholder="Insira aqui o link da Pesquisa de Dor"
                  required
                  value={researchLink}
                  onChange={(event) => setResearchLink(event.target.value)}
                />
                <Label for="researchLink">
                  Insira aqui o link da Pesquisa de Dor
                </Label>
              </FormGroup>
            </Col>
            <Col md={4} className="pain-survey-column">
              <Button
                onClick={() => {}}
              >
                Acessar Tutorial
              </Button>
            </Col>
          </Row>
          {surveyAnswers.map((surveyAnswer: SurveyAnswerSummary, idx: number) => (
            <div key={`answers${idx}`}>
              <h3>{surveyAnswer.label}</h3>
              <Row>
                <Col className="pain-survey-column" md={4}>
                  <Label>Número de Respostas:</Label>
                  <Input
                      name="answerNumber"
                      type="number"
                      required
                      value={surveyAnswer.number}
                      onChange={(event) => {
                        const answers = [...surveyAnswers]
                        answers[idx].number = parseInt(event.target.value)
                        answers[idx].remaining = answers[idx].goal > answers[idx].number ? answers[idx].goal - answers[idx].number : 0
                        setSurveyAnswers(answers)
                      }}
                    />
                </Col>
                <Col className="pain-survey-column" md={4}>
                  <Label>Meta de Respostas:</Label>
                  <Input
                    name="answerGoal"
                    type="number"
                    readOnly={true}
                    value={surveyAnswer.goal}
                    onChange={(event) => {}}
                  />
                </Col>
                <Col className="pain-survey-column" md={4}>
                  <Label>Faltam para a Meta:</Label>
                  <Input
                    name="answerRemaining"
                    type="number"
                    readOnly={true}
                    value={surveyAnswer.remaining}
                    onChange={(event) => {}}
                  />
                </Col>
              </Row>
            </div>
          ))}    
        </form>
      </section>
    </div>
  )
}