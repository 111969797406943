import React, { useEffect, useState } from "react";
import { AccelerationService } from "../../../../services";
import {
  Acceleration,
  SearchParams,
  SearchResultInfo,
} from "../../../../entities";
import Layout from "../../../../components/layout/Layout";
import DataTable from "../../../../components/dataTable/DataTable";
import { Button } from "@chakra-ui/react";
import { GrView } from "react-icons/gr";
import { useNavigate } from "react-router-dom";

export default function AccelerationListPage() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [accelerations, setAccelerations] = useState<Acceleration[]>([]);
  const [resultInfo, setResultInfo] = useState(new SearchResultInfo());

  useEffect(() => {
    load();
  }, []);

  const load = (page = 1) => {
    setLoading(true);

    const params = new SearchParams([], page, 20);
    AccelerationService.search(params)
      .then((result) => {
        setAccelerations(result.data);
        setResultInfo(result.info);
      })
      .finally(() => setLoading(false));
  };

  const renderActions = (params: any) => {
    return (
      <div className="table-action-button-container">
        <Button onClick={() => navigate(`/acceleration/${params.id}`)}>
          <GrView />
        </Button>
        {/* <Button onClick={() => deleteItem(params.id)} className="table-action-button"><GrTrash /></Button> */}
      </div>
    );
  };

  const columns = [
    { field: "name", headerName: "Nome" },
    { headerName: "Ações", renderCell: renderActions },
  ];

  return (
    <Layout internal={true}>
      <DataTable
        title="Administradores do Licenciado"
        checkboxSelection={false}
        rows={accelerations}
        columns={columns}
        pagination={resultInfo}
        onPageChange={(page: number) => load(page)}
        loading={loading}
      />
    </Layout>
  );
}
