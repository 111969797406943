import React, { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { AuthenticationService } from "../../services/AuthenticationService"
import UserStorage from "../../util/userStorage"
import "./MenuInternal.scss"

export default function MenuInternal(props: any) {
  const navigate = useNavigate()
  const user = UserStorage.get()

  useEffect(() => {
    validateUser()
    // eslint-disable-next-line
  }, [])

  const validateUser = async () => {
    if (UserStorage.hasToken() === false) logout()

    const authService = new AuthenticationService()

    try {
      const token = UserStorage.getToken()
      await authService.getUserByToken(token)
    } catch (error) {
      logout()
    }
  }

  const logout = () => {
    UserStorage.logout()
    navigate("/login")
  }

  return (
    <nav className="menu-internal-container">
      <div className="burger-container">
        <input type="checkbox" id="checkbox-menu" />
        <label htmlFor="checkbox-menu">
          <span></span>
          <span></span>
          <span></span>
        </label>
      </div>
      <ul className="menu-internal">
        <li className="menu-internal-item">
          <Link to="/painel">Dashboard</Link>
        </li>
        {user?.role?.code === "admin" && (
          <>
            <li className="menu-internal-item">
              <Link to="/licenciado">Licenciados</Link>
            </li>
            <li className="menu-internal-item">
              <Link to="/startup">Startups</Link>
            </li>
            <li className="menu-internal-item">
              <Link to="/mentor">Mentores</Link>
            </li>
            <li className="menu-internal-item">
              <Link to="/investidor">Investidores</Link>
            </li>
            <li className="menu-internal-item">
              <Link to="/master/me">Perfil</Link>
            </li>
            <li className="menu-internal-item">
              <Link to="/drive">Arquivos</Link>
            </li>
          </>
        )}
        {user?.role?.code === "licensed" && (
          <>
            {/* <li className="menu-internal-item"><Link to="/licenciado/admin">Administradores</Link></li> */}
            <li className="menu-internal-item">
              <Link to="/startup">Startups</Link>
            </li>
            <li className="menu-internal-item">
              <Link to="/mentor">Mentores</Link>
            </li>
            <li className="menu-internal-item">
              <Link to="/investidor">Investidores</Link>
            </li>
            <li className="menu-internal-item">
              <Link to="/licenciado/me">Perfil</Link>
            </li>
            <li className="menu-internal-item">
              <Link to="/drive">Arquivos</Link>
            </li>
          </>
        )}
        {user?.role?.code === "startup" && (
          <>
            <li className="menu-internal-item">
              <Link to="/acceleration/646ceeafa38ce7c9a80471d9">Fases</Link>
            </li>
            <li className="menu-internal-item">
              <Link to="/startup/me">Perfil</Link>
            </li>
          </>
        )}
        {user?.role?.code === "founder" && (
          <>
            <li className="menu-internal-item">
              <Link to="/acceleration/646ceeafa38ce7c9a80471d9">Fases</Link>
            </li>
            <li className="menu-internal-item">
              <Link to="/startup/id">Perfil</Link>
            </li>
            <li className="menu-internal-item">
              <Link to="/startup/fundador/me">Perfil</Link>
            </li>
          </>
        )}
        {user?.role?.code === "mentor" && (
          <>
            <li className="menu-internal-item">
              <Link to="/mentor/me">Perfil</Link>
            </li>
          </>
        )}
        {user?.role?.code === "investor" && (
          <>
            <li className="menu-internal-item">
              <Link to="/investidor/me">Perfil</Link>
            </li>
          </>
        )}
        <li className="menu-internal-item">
          <Link to="/perfil/alterar-senha">Alterar Senha</Link>
        </li>
        <li className="menu-internal-item">
          <Link to="/central-de-ajuda">Central de Ajuda</Link>
        </li>
        <li className="menu-internal-item">
          <Link to="/" onClick={() => UserStorage.logout()}>
            Sair
          </Link>
        </li>
      </ul>
    </nav>
  )
}
