import { Acceleration, SearchParams, SearchResult } from "../../entities";
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()
export class AccelerationRepository {
    public static async search(params: SearchParams, token: string): Promise<SearchResult<Acceleration>> {
        const headers = { Authorization: token };
        const response = await client.DoRequest('GET', `/acceleration?page=${params.page}&limit=${params.limit}`, {}, headers)

        const result = new SearchResult<Acceleration>(response.data, response.info)

        return result
    }

    public static async get(id: string, token: string): Promise<Acceleration> {
        const headers = { Authorization: token };
        const response = await client.DoRequest('GET', `/acceleration/${id}`, {}, headers)  

        return new Acceleration(response)
    }
}