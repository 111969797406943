import { Acceleration, SearchParams, SearchResult } from "../../entities";
import { AccelerationRepository } from "../../repositories/startup";
import UserStorage from "../../util/userStorage";

export class AccelerationService {
    public static async search(params: SearchParams): Promise<SearchResult<Acceleration>> {
        const token = ''
        const result = AccelerationRepository.search(params, token)
        return result
    }

    public static async get(id: string): Promise<Acceleration> {
        const token = UserStorage.getToken()
        const acceleration = AccelerationRepository.get(id, token)
        return acceleration
    }
}