import React, { useEffect, useState } from "react"
import Layout from "../../../../components/layout/Layout"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Link as ReactRouterLink } from "react-router-dom"
import { Flex, Link as ChakraLink } from "@chakra-ui/react"
import UserStorage from "../../../../util/userStorage"
import { UserService } from "../../../../services/UserService"
import { BusinessCategory, SearchParams, User } from "../../../../entities"
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import InputMask from "react-input-mask"
import "../../Dashboard.scss"
import { BusinessCategoryService } from "../../../../services/startup/BusinessCategoryService"
import { useWhitelabel } from "../../../../stores/WhiteLabelStore"

export default function StartupFormPage() {
  const whitelabel = useWhitelabel().get()

  const navigate = useNavigate()
  const params = useParams()
  const [user, setUser] = useState(
    new User({
      role: { code: "startup" },
    })
  )
  const [founders, setFounders] = useState<User[]>([])
  const [businessCategories, setBusinessCategories] = useState<
    BusinessCategory[]
  >([])

  useEffect(() => {
    if (params?.id === "me") {
      const user = UserStorage.get()
      if (user?.role?.code !== "startup") return
      if (!user?.id) return
      load(user.id)
    } else if (params?.id !== "0") {
      if (!params.id) return
      load(params.id)
    }

    loadBusinessCategories()

    // eslint-disable-next-line
  }, [])

  const load = async (id: string) => {
    const user = await UserService.get(id)
    setUser(user)

    loadFounders(user.id)
  }

  const loadFounders = async (startupId: string) => {
    const founders = await UserService.getFounders(startupId)
    setFounders(founders)
  }

  const loadBusinessCategories = async () => {
    const params = new SearchParams([], 1, 9999)
    const result = await BusinessCategoryService.search(params)

    setBusinessCategories(result.data)
  }

  const save = async (event: any) => {
    event.preventDefault()

    if (!user.id) {
      if (user.password?.length < 6) {
        alert("A senha deve ter pelo menos 6 caracteres.")
        return false
      }
    }

    await UserService.save(user)

    if (params?.id === "me") {
      alert("Perfil salvo com sucesso.")
    } else {
      navigate("/startup")
    }

    return false
  }

  const changeFieldValue = (code: string, value: any) => {
    const userNew = new User(user)
    userNew.setAttributeValue(code, value)
    setUser(userNew)
  }

  const changeFieldValueFromArray = (code: string, key: string, value: any) => {
    const userNew = new User(user)
    if (value) {
      console.log(code, key, value)
      userNew.addUpdateAttributeValueFromArray(code, key, value)
    } else {
      userNew.deleteAttributeValueFromArray(code, key)
    }

    setUser(userNew)
  }

  return (
    <Layout internal={true}>
      <section className="profile-forms">
        <Form onSubmit={save}>
          <Row>
            <Col md={12}>
              <div className="title">
                <h1>Cadastro de Startup</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="startupName"
                  name="startupName"
                  placeholder="Digite o nome da Startup"
                  type="text"
                  required
                  value={user.getAttributeValue("name")}
                  onChange={(event) =>
                    changeFieldValue("name", event.currentTarget.value)
                  }
                />
                <Label for="startupName">Nome da Startup</Label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="email"
                  name="email"
                  placeholder="Digite o email"
                  type="email"
                  required
                  value={user.email}
                  onChange={(event) =>
                    setUser(
                      new User({ ...user, email: event.currentTarget.value })
                    )
                  }
                />
                <Label for="email">Email</Label>
              </FormGroup>
            </Col>
          </Row>
          {!user.id && (
            <Row>
              <Col md={6}>
                <FormGroup floating>
                  <Input
                    id="password"
                    name="password"
                    placeholder="Digite a senha"
                    type="password"
                    value={user.password}
                    onChange={(event) =>
                      setUser(
                        new User({
                          ...user,
                          password: event.currentTarget.value,
                        })
                      )
                    }
                  />
                  <Label for="password">Senha</Label>
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row>
            <Col md={12}>
              <div className="sub-title">
                <h2>Informações da Startup</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup className="separated-label" floating>
                <Input
                  id="haveCnpj"
                  name="haveCnpj"
                  type="select"
                  value={user.getAttributeValue("hasDocument")}
                  onChange={(event) =>
                    changeFieldValue("hasDocument", event.currentTarget.value)
                  }
                >
                  <option value="" disabled>
                    Possui CNPJ?
                  </option>
                  <option>Sim</option>
                  <option>Não</option>
                </Input>
                <Label>Possui CNPJ?</Label>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup floating>
                <Input
                  tag={InputMask}
                  mask="99.999.999/9999-99"
                  maskChar="-"
                  id="cnpj"
                  name="cnpj"
                  placeholder="Digite o CNPJ"
                  type="text"
                  value={user.getAttributeValue("document")}
                  // onChange={(event) => changeFieldValue('document', event.currentTarget.value)}
                  onChange={(event) => {
                    let val = event.target.value
                    val = val.replace(
                      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                      "$1.$2.$3/$4-$5"
                    )
                    changeFieldValue("document", val)
                  }}
                />
                <Label for="cnpj">CNPJ</Label>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup floating>
                <Input
                  id="companyName"
                  name="companyName"
                  placeholder="Digite a razão social"
                  type="text"
                  value={user.getAttributeValue("companyName")}
                  onChange={(event) =>
                    changeFieldValue("companyName", event.currentTarget.value)
                  }
                />
                <Label for="companyName">Razão social</Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup floating>
                <Input
                  id="url"
                  name="url"
                  placeholder="Digite a URL do site"
                  type="text"
                  value={user.getAttributeValue("url")}
                  onChange={(event) =>
                    changeFieldValue("url", event.currentTarget.value)
                  }
                />
                <Label for="url">URL do site</Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="sub-title">
                <h2>Vertical</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="separated-label">
                <Input
                  id="category"
                  name="category"
                  type="select"
                  value={user.getAttributeValue("businessCategory")}
                  onChange={(event) =>
                    changeFieldValue(
                      "businessCategory",
                      event.currentTarget.value
                    )
                  }
                >
                  <option value="" selected disabled>
                    Selecione uma categoria
                  </option>
                  {businessCategories.map((category) => (
                    <option value={category.id}>{category.name}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="sub-title">
                <h2>Modelos de Negócio</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={1}>
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  id="b2b"
                  checked={user.getAttributeValueFromArray(
                    "businessModel",
                    "b2b"
                  )}
                  onChange={(event) =>
                    changeFieldValueFromArray(
                      "businessModel",
                      "b2b",
                      event.target.checked
                    )
                  }
                />
                <Label for="b2b">B2B</Label>
              </FormGroup>
            </Col>
            <Col md={1}>
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  id="b2c"
                  checked={user.getAttributeValueFromArray(
                    "businessModel",
                    "b2c"
                  )}
                  onChange={(event) =>
                    changeFieldValueFromArray(
                      "businessModel",
                      "b2c",
                      event.target.checked
                    )
                  }
                />
                <Label for="b2c">B2C</Label>
              </FormGroup>
            </Col>
            <Col md={1}>
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  id="b2e"
                  checked={user.getAttributeValueFromArray(
                    "businessModel",
                    "b2e"
                  )}
                  onChange={(event) =>
                    changeFieldValueFromArray(
                      "businessModel",
                      "b2e",
                      event.target.checked
                    )
                  }
                />
                <Label for="b2e">B2E</Label>
              </FormGroup>
            </Col>
            <Col md={1}>
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  id="b2g"
                  checked={user.getAttributeValueFromArray(
                    "businessModel",
                    "b2g"
                  )}
                  onChange={(event) =>
                    changeFieldValueFromArray(
                      "businessModel",
                      "b2g",
                      event.target.checked
                    )
                  }
                />
                <Label for="b2g">B2G</Label>
              </FormGroup>
            </Col>
            <Col md={1}>
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  id="b2b2c"
                  checked={user.getAttributeValueFromArray(
                    "businessModel",
                    "b2b2c"
                  )}
                  onChange={(event) =>
                    changeFieldValueFromArray(
                      "businessModel",
                      "b2b2c",
                      event.target.checked
                    )
                  }
                />
                <Label for="b2b2c">B2B2C</Label>
              </FormGroup>
            </Col>
            <Col md={1}>
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  id="c2c"
                  checked={user.getAttributeValueFromArray(
                    "businessModel",
                    "c2c"
                  )}
                  onChange={(event) =>
                    changeFieldValueFromArray(
                      "businessModel",
                      "c2c",
                      event.target.checked
                    )
                  }
                />
                <Label for="c2c">C2C</Label>
              </FormGroup>
            </Col>
            <Col md={1}>
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  id="d2c"
                  checked={user.getAttributeValueFromArray(
                    "businessModel",
                    "d2c"
                  )}
                  onChange={(event) =>
                    changeFieldValueFromArray(
                      "businessModel",
                      "d2c",
                      event.target.checked
                    )
                  }
                />
                <Label for="d2c">D2C</Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="sub-title">
                <h2>Fundadores</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Flex
              mb="0.5rem"
              w="100%"
              alignItems="center"
              justifyContent="center"
            >
              <ChakraLink
                _hover={{
                  textDecoration: "none",
                  color: `${whitelabel?.colors?.quinary}`,
                }}
                bg={whitelabel?.colors?.primary}
                padding="0.25rem 1rem"
                borderRadius="0.375rem"
                color={whitelabel?.colors?.quinary}
                as={ReactRouterLink}
                to={`/startup/fundador/0?startup=${user.id}`}
              >
                Adicionar Fundador
              </ChakraLink>
            </Flex>
          </Row>
          <Row>
            {founders.map((founder) => (
              <Col md={12} key={`founder-${founder.id}`}>
                <Link to={`/startup/fundador/${founder.id}`}>
                  {founder.email}
                </Link>
              </Col>
            ))}
          </Row>
          <Button type="submit">Salvar</Button>
        </Form>
      </section>
    </Layout>
  )
}
